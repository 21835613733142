
import CommonMixin from '@/mixins/CommonMixin';
import PaginationMixin from '@/mixins/PaginationMixin';

import Sensor from '@/models/Sensor';
import Order from '@/models/Order';
import { handleErrorUI } from '@/util/error';
import filter from 'lodash/filter';
import each from 'lodash/each';
import SensorCard from '@/components/Store/Sensor/Card.vue';
import CartDrawer from '@/components/Store/Sensor/CartDrawer.vue';
import StoreDrawer from '@/components/Store/Sensor/StoreDrawer.vue';
import OrderSummaryCard from '@/components/Store/Sensor/OrderSummaryCard.vue';
import OrderConfirmedCard from '@/components/Store/Sensor/OrderConfirmedCard.vue';

interface OrderEvent {
  order: Order;
  index: number;
}

export default {
  components: {
    OrderConfirmedCard,
    OrderSummaryCard,
    StoreDrawer,
    CartDrawer,
    SensorCard
  },
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      loading: false,
      stores: [],
      sensors: [],
      filter: {
        name: null,
        visibility: 'SHOWN'
      },
      drawerShown: false,
      dialogShown: false,
      activeDrawer: 'CartDrawer',
      activeDialog: 'OrderSummaryCard',
      orders: [],
      reviewedOrders: [],
      orderMap: new Map<string, Order>(),
      selectedOrder: null,
      selectedIndex: -1,
      tax: null
    };
  },
  async created() {
    this.tax = await this.$repo.tax.getTax();
  },
  mounted() {
    this.onFilterChange(this.filter);
    this.loadStores();
    this.loadSensors();
    this.loadOrders();
  },
  methods: {
    openDrawer(drawer: string) {
      this.activeDrawer = drawer;
      this.drawerShown = true;
    },
    async addToCart(sensor: Sensor) {
      if (!this.orderMap.has(sensor.id)) {
        const order: Order = {
          sensor: sensor,
          storeOrders: []
        };
        this.orders.push(order);
        this.orderMap.set(sensor.id, order);
        await this.$repo.order.saveCart(this.orders);
      }
      this.openDrawer('CartDrawer');
    },
    addStore({ order, index }: OrderEvent) {
      this.selectedOrder = order;
      this.selectedIndex = index;
      this.openDrawer('StoreDrawer');
    },
    handleFilter(value) {
      this.filter = Object.assign({}, this.filter, {
        name: value
      });
      this.onFilterChange(this.filter);
      this.loadSensors();
    },
    async updateOrder({ order, index }: OrderEvent) {
      const orders = [...this.orders];
      orders[index] = order;
      await this.$repo.order.saveCart(this.orders);
    },
    async updateStoreOrder({ order, index }: OrderEvent) {
      const orders = [...this.orders];
      orders[index] = order;
      this.orders = orders;
      await this.$repo.order.saveCart(this.orders);
      this.openDrawer('CartDrawer');
    },
    async removeOrder({ order }: OrderEvent) {
      const sensorId = order.sensor.id;
      const orders = [...this.orders];
      this.orders = filter(orders, order => {
        if (order.sensor.id !== sensorId) return true;
        this.orderMap.delete(sensorId);
        return false;
      });
      await this.$repo.order.saveCart(this.orders);
    },
    reviewOrders() {
      const orders = [...this.orders];
      this.reviewedOrders = filter(orders, order => {
        return order.storeOrders.length > 0;
      });
      this.drawerShown = false;
      this.dialogShown = true;
    },
    async submitOrders() {
      this.activeDialog = 'OrderConfirmedCard';
    },
    loadStores() {
      this.$repo.store
        .getStores()
        .then(({ data }) => (this.stores = data), handleErrorUI);
    },
    async loadSensors() {
      this.loading = true;
      try {
        const { sensors, pagination } = await this.$repo.sensor.getSensors(
          this.pagination
        );
        this.sensors = sensors;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    loadOrders() {
      this.$repo.order.loadCart().then(orders => {
        this.orders = orders;
        each(orders, order => {
          this.orderMap.set(order.sensor.id, order);
        });
      });
    },
    async onCloseOrderConfirmation() {
      this.dialogShown = false;
      await this.$repo.order.resetCart();
      this.reset();
    },
    reset() {
      this.activeDialog = 'OrderSummaryCard';
      this.orderMap = new Map<string, Order>();
      this.loadOrders();
    }
  }
};
